import * as React from "react"
import { graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import Page from '../../components/Page'
import OtherIndustries from "../../components/OtherIndustries";
import IndustriesHeroSection from "../../components/IndustriesHeroSection";
import IndustriesBanner from "../../components/IndustriesBottomBanner";

const AutomotivePage = ({ data }) => {
  return (
    <Page>
      <IndustriesHeroSection
      img={data.file && data.file}
      heading="Retail"
      content="Create an engaging and more personalized experience for your customers."
      />
      <section className="bg-gray-100 pt-10 pb-10">
        <div className="max-w-6xl mx-auto flex lg:flex-row flex-col px-4">
          <div className="lg:w-1/4 w-full">
            <h2 className="text-3xl font-bold mb-10 lg:w-52 capitalize">why loopr</h2>
          </div>
          <div className="lg:w-3/4 w-full flex md:flex-row flex-col justify-between">
            <div className="flex flex-col w-100">
              <svg xmlns="http://www.w3.org/2000/svg" width="34.748" height="34.713" viewBox="0 0 34.748 34.713"><g id="Group_147" data-name="Group 147" transform="translate(0 0.5)"><rect id="Rectangle_18" data-name="Rectangle 18" width="22.549" height="29.736" rx="2" transform="translate(0 4.475)" fill="#d3eaff"></rect><rect id="Rectangle_19" data-name="Rectangle 19" width="17.412" height="17.412" transform="translate(0.534 16.301)" fill="none" stroke="#3c4fe0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></rect><path id="Path_43" data-name="Path 43" d="M193.227,59.74V51.934H226.94V85.647h-7.805" transform="translate(-192.693 -51.934)" fill="none" stroke="#3c4fe0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></path><rect id="Rectangle_20" data-name="Rectangle 20" width="25.907" height="25.907" transform="translate(0.534 7.805)" fill="none" stroke="#3c4fe0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" stroke-dasharray="3 6"></rect><g id="Group_111" data-name="Group 111" transform="translate(22.307 8.495) rotate(45)"><path id="Path_44" data-name="Path 44" d="M0,0V8.585" transform="translate(2.621 0)" fill="none" stroke="#3c4fe0" strokeLinecap="round" strokeWidth="1"></path><path id="Path_45" data-name="Path 45" d="M0,2.622,2.621,0,5.243,2.622" transform="translate(0 0)" fill="none" stroke="#3c4fe0" strokeLinecap="round" strokeWidth="1"></path></g></g></svg>
              <h2 className="font-bold text-xl py-4">Easy to Use Platform</h2>
              <p className="text-base">Works with teams and projects of all sizes</p>
            </div>
            <div className="flex flex-col w-100">
              <svg xmlns="http://www.w3.org/2000/svg" width="39.933" height="35.278" viewBox="0 0 39.933 35.278"><g id="Group_304" data-name="Group 304" transform="translate(0.68 0.5)"><path id="Path_68" data-name="Path 68" d="M438.272,352.2l-7.451,4.3v8.993l7.521,4.586,7.577-4.586V356.6Z" transform="translate(-419.212 -339.007)" fill="#d3eaff"></path><path id="Path_69" data-name="Path 69" d="M431.441,380.032l-9.053-5.578v-10.66l9.053,5.578Z" transform="translate(-414.303 -345.754)" fill="none" stroke="#3c4fe0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></path><path id="Path_70" data-name="Path 70" d="M453.057,380.032l9.331-5.578v-10.66l-9.331,5.578Z" transform="translate(-432.157 -345.754)" fill="none" stroke="#3c4fe0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></path><path id="Path_71" data-name="Path 71" d="M426.084,348.169l9.528,5.838,9.528-5.838-9.672-5.51Z" transform="translate(-416.454 -333.449)" fill="none" stroke="#3c4fe0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></path><path id="Path_72" data-name="Path 72" d="M445.464,320.619v7.1l2.42-2.421-2.42,2.421-2.457-2.384" transform="translate(-426.307 -320.619)" fill="none" stroke="#3c4fe0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></path><path id="Path_73" data-name="Path 73" d="M403.037,394.941l5.98-3.3-3.289-.948,3.289.948-.9,3.3" transform="translate(-403.037 -361.412)" fill="none" stroke="#3c4fe0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></path><path id="Path_74" data-name="Path 74" d="M487.024,394.941l-5.98-3.3,3.289-.948-3.289.948.9,3.3" transform="translate(-448.451 -361.412)" fill="none" stroke="#3c4fe0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></path></g></svg>
              <h2 className="font-bold text-xl py-4">Industry-Specific Models</h2>
              <p className="text-base">100+ models ready to be deployed across industries</p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:p-10 py-10 px-0">
        <div className="max-w-7xl mx-auto flex lg:flex-row flex-col px-4">
          <div className="lg:w-1/4 w-full">
            <h2 className="text-3xl font-bold mb-2 lg:w-52 capitalize">Use cases</h2>
            <p className="text-base">The future of Agriculture has arrived.</p>
          </div>

          <div className="lg:w-3/4 w-full grid md:grid-cols-3 grid-cols-1 gap-10 justify-between">
            <div className="w-100 flex flex-col">
              <StaticImage src="../../assets/images/retail-1.png" alt="" className="w-full" />
              <h6 className="text-gray-400 py-3 font-bold">01</h6>
              <h4 className="text-xl text-black font-bold capitalize pb-1">Inventory Monitoring</h4>
              <p className="text-base">Helps in monitoring on-shelf inventory to generate a warning before there is any product shortage.</p>
            </div>
            <div className="w-100 flex flex-col">
              <StaticImage src="../../assets/images/retail-2.png" alt="" className="w-full" />
              <h6 className="text-gray-400 py-3 font-bold">02</h6>
              <h4 className="text-xl text-black font-bold capitalize pb-1">Sentiment Analysis</h4>
              <p className="text-base">Understand your customer requirements and feedback on the product without reading thousands of comments</p>
            </div>
            <div className="w-100 flex flex-col">
              <StaticImage src="../../assets/images/retail-3.png" alt="" className="w-full" />
              <h6 className="text-gray-400 py-3 font-bold">03</h6>
              <h4 className="text-xl text-black font-bold capitalize pb-1">Search Relevancy</h4>
              <p className="text-base">Improve search experience with better, more accurate, and rapid results using text as well as visual search.</p>
            </div>

            <div className="w-100 flex flex-col">
              <StaticImage src="../../assets/images/retail-4.png" alt="" className="w-full" />
              <h6 className="text-gray-400 py-3 font-bold">04</h6>
              <h4 className="text-xl text-black font-bold capitalize pb-1">Personalisation & Recommendation</h4>
              <p className="text-base">Offers contextual, personalized relevant results and proactive recommendations.</p>
            </div>
            <div className="w-100 flex flex-col">
              <StaticImage src="../../assets/images/retail-5.png" alt="" className="w-full" />
              <h6 className="text-gray-400 py-3 font-bold">04</h6>
              <h4 className="text-xl text-black font-bold capitalize pb-1">Automated Cataloguing</h4>
              <p className="text-base">Create automated tags to enhance operational efficiency and improve data accuracy.</p>
            </div>

          </div>
        </div>
      </section>


      <section className="xl:p-10 py-10 px-0">
        <div className="max-w-7xl mx-auto flex lg:flex-row flex-col px-4 gap-10">
          <div className="lg:w-1/4 w-full">
            <h2 className="text-3xl font-bold mb-2 lg:w-52 capitalize">Case Studies</h2>
            <p className="text-base">Help India's largest cement manufacturer expand into the house building space.</p>
          </div>

          <div className="lg:w-3/4 w-full">
            <div className="w-100">
              <StaticImage src="../../assets/images/retail-casestudy.png" alt="" />
              <div className="w-full flex md:flex-row flex-col gap-10 justify-between mt-10">
                <div className="flex flex-col">
                  <p className="text-base">In order to address the challenges faced in Image Search, a customised, step by step approach was needed to implement and handle the large volumes of images uploaded.</p>
                  <h5 className="text-2xl text-blue-400 font-bold pt-6">400,000</h5>
                  <p className="text-base">Images annotations done</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-base">Loopr built a custom Image Search Engine for the client to help their users search for Home Exterior Designs from a huge catalog of designs uploaded by their partner Architects and Designers.</p>
                  <h5 className="text-2xl text-blue-400 font-bold pt-6">5 Weeks</h5>
                  <p className="text-base">taken to deploy model</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <OtherIndustries />
      <IndustriesBanner/>
    </Page >
  )
}

export default AutomotivePage

export const query = graphql`
  query retailUses {
    file(relativePath: {eq: "retail-hero.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    allMarkdownRemark(sort: {fields: frontmatter___index, order: ASC}){
    nodes {
      frontmatter {
        image
        index
        title
        category
        mobileImage
      }
      html
      id
    }
  }
  }
`
